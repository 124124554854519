<template>
  <div class="app-main-content">
    <card
        title="财务"
        :tabs="[{cat_name: '支出', cat_code: '0'}, {cat_name: '收入', cat_code: '1'}]"
        v-model="listQuery.money_status"
        body-class="overflow-hidden page-card-body"
        @change="search"
    >
      <template #tools>
        <div>
          <a-button type="primary" @click="openModal('create', {})">
            <i class="iconfont icon-jia" style="margin-right: 4px;"></i>
            财务明细
          </a-button>
        </div>
        <div class="right">
          <div class="item">
            <div class="label">标签</div>
            <a-select style="width: 210px;height: 32px;overflow: hidden;" :maxTagCount="1" class="sort-select"
                      mode="multiple" v-model="listQuery.money_tags" @change="search">
              <a-select-option v-for="item in cat.money_tags" :key="item.cat_code" :value="item.cat_name">
                {{ item.cat_name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">排序</div>
            <a-select class="sort-select" v-model="listQuery.order_by" @change="search" style="width: 140px;">
              <a-select-option value="">默认</a-select-option>
              <a-select-option value="z.business_date asc">收/付款时间正序</a-select-option>
              <a-select-option value="z.business_date desc">收/付款时间倒序</a-select-option>
            </a-select>
          </div>
          <div class="item">
            <div class="label">收付款时间</div>
            <a-range-picker @change="search" format="YYYY/MM/DD" value-format="YYYY/MM/DD" class="search-date-range"
                            v-model="listQuery.dateRange" />
          </div>
          <div class="item">
            <div class="label">搜索</div>
            <a-input v-model="listQuery.keywords" placeholder="请输入标题">
              <a-icon slot="prefix" type="search"></a-icon>
            </a-input>
          </div>
          <div class="item">
            <a-button class="search" @click="search">查询</a-button>
          </div>
          <div class="item">
            <a-dropdown>
              <img class="more" :src="$toUrl('more.png')">
              <a-menu slot="overlay" @click="selectActionMenu">
                <a-menu-item key="export">导出</a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </div>
      </template>
      <div class="body-flex">
        <div class="body-left-table">
          <a-table
              style="min-width: 1080px;"
              :data-source="list"
              rowKey="oid"
              :pagination="pagination"
              @change="paginationChange"
              :customRow="customRow"
          >
            <a-table-column title="ID" data-index="id" width="70px">
              <template slot-scope="_, row">
                <span class="">{{ row.seqid }}</span>
              </template>
            </a-table-column>
            <a-table-column title="操作人" width="150px">
              <template v-slot:default="_, row">
                <div class="edp-title">
                  <div style="display: flex;align-items: center">
                    <span class="money_status red" v-if="row.money_status === '0'">支</span>
                    <span class="money_status green" v-if="row.money_status === '1'">收</span>
                    <span class="name">{{
                        row.reviser_title ? row.reviser_name + '-' + row.reviser_title : row.reviser_name
                      }}</span>
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="任务" data-index="issue_name" width="200px">
              <template v-slot="_, row">
                <div class="edp-tag" style="cursor: pointer;" @click.stop="openTaskDetailModal(row.issueoid)">
                  {{ row.issue_name }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="财务标签" width="280px">
              <template v-slot:default="_, row">
                <div v-if="row.money_tag">
                  <div class="edp-tag" v-for="(item, key) in row.money_tag.split(',')" :key="key"
                       style="margin-right: 10px;">{{ item }}
                  </div>
                </div>
              </template>
            </a-table-column>
            <a-table-column title="发生金额" width="160px">
              <template v-slot:default="_, row">
                <div>
                  {{ row.money_type }}
                  {{ row.amount | numberFormat }}
                </div>
              </template>
            </a-table-column>
            <a-table-column title="发生时间" width="120px">
              <template #default="_, row">
                {{ row.business_date }}
              </template>
            </a-table-column>
            <a-table-column title="系统时间" width="120px">
              <template #default="_, row">
                <div class="no-wrap">
                  {{ row.update_time }}
                </div>
              </template>
            </a-table-column>
          </a-table>
        </div>
        <div class="body-right-info edp-right-detail" style="--miniDetailWidth: 400px;" v-if="hasDetail">
          <div class="info-scroll-content">
            <div class="content-head">
              <div class="content-head-id">
                <img :src="`${$appsettings.imgPrefix}product_inventory_blue.png`" alt="">
                <span class="id">#{{ detail.seqid }}</span>
              </div>
            </div>
            <div class="scroll-content-status" style="margin-top: 30px;">
              <div class="status-item">
                <img v-if="detail.reviser_pic" class="round" :src="detail.reviser_pic" alt="">
                <span v-else class="round"></span>
                <div class="status-item-right">
                  <div class="status-title">
                    {{ detail.reviser_title ? detail.reviser_name + '-' + detail.reviser_title : detail.reviser_name }}
                  </div>
                  <div class="status-sub-text">操作人</div>
                </div>
              </div>
              <div class="status-item">
                <img class="round" :src="`${$appsettings.imgPrefix}task_status_yellow.png`" alt="">
                <div class="status-item-right">
                  <div class="status-title">
                    {{ detail.update_time }}
                  </div>
                  <div class="status-sub-text">操作时间</div>
                </div>
              </div>
              <div class="status-item">
                <img class="round" :src="`${$appsettings.imgPrefix}level.png`" alt="">
                <div class="status-item-right">
                  <div class="status-title">
                    {{ detail.money_status === '0' ? "支出" : "收入" }}
                  </div>
                  <div class="status-sub-text">财务类型</div>
                </div>
              </div>
            </div>
            <div class="title" style="font-size: 16px;font-weight: 500">
              描述
              <!--              <a-button type="link">全屏查看</a-button>-->
            </div>
            <!--            !!!-->
            <!--            目前用的原生输入框 后期很可能需要换成富文本 注意这里-->
            <!--            !!!-->
            <div style="margin-top: 10px;">
              {{ detail.remark }}
            </div>
            <div class="content-group-info">
              <!--              <div class="group-info-title">属性</div>-->
              <div class="group-info-list">
                <div class="info-item">
                  <div class="info-key">实际时间</div>
                  <div class="info-value">
                    {{ detail.business_date }}
                  </div>
                </div>
                <div class="info-item">
                  <div class="info-key">实际金额</div>
                  <div class="info-value">
                    {{ detail.money_type }}
                    {{ detail.amount }}
                  </div>
                </div>
                <div class="info-item">
                  <div class="info-key">财务标签</div>
                  <div class="info-value">
                    <div v-if="detail.money_tag">
                      <div class="edp-tag" v-for="(item, key) in detail.money_tag.split(',')" :key="key"
                           style="margin-right: 10px;font-size: 12px;padding: 0 5px;">{{ item }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="info-item">
                  <div class="info-key">关联任务</div>
                  <div class="info-value">
                    {{ detail.issue_name }}
                  </div>
                </div>
                <attachment-upload :prj-oid="detail.prjoid" :source-oid="detail.oid" source-type="6">
                  <template slot="title">
                        <span style="font-size: 16px">
                          附件上传
                        </span>
                  </template>
                </attachment-upload>
              </div>
            </div>
          </div>
        </div>
      </div>
    </card>
    <money-log-modal :prj-oid="$route.query.prj_oid" @reload="search" ref="modal"></money-log-modal>
    <task-modal @reload="getList" ref="taskDetailModal"></task-modal>
  </div>
</template>

<script>
import listMixin from "@/utils/mixins/listMixin.js";
import { fetch } from "@/utils/request.js";
import MoneyLogModal from "@/components/moneyLogModal.vue";
import attachmentUpload from "@/components/attachmentUpload.vue";
import catMixin from "@/utils/mixins/catMixin.js";
import TaskModal from "@/components/taskModal.vue";
import dayjs from "dayjs";

export default {
  components: { TaskModal, attachmentUpload, MoneyLogModal },
  mixins: [listMixin, catMixin],
  data() {
    return {
      comment: "",
      detailOid: "",
      cat: {
        issues_level: [],
        issue_bstatus: [],
        money_tags: [],
      },
      listMixinOptions: {
        mockUrl: "http://127.0.0.1:4523/m1/2234408-0-default/prj/moneylog/retrieve",
        url: "/prj/moneylog/retrieve"
      },
      listQuery: {
        prjoid: this.$route.query.prj_oid,
        money_status: "",
        money_tags: [],
        order_by: "",
        dateRange: []
      },
      detail: {},
      hasDetail: false
    }
  },
  methods: {
    listFilter(list) {
      return list.map(item => {
        return {
          ...item,
          business_date: dayjs(item.business_date, "YYYY/MM/DD HH:mm").format("YYYY/MM/DD HH:mm")
        }
      })
    },
    openTaskDetailModal(oid) {
      this.$refs.taskDetailModal.open(oid)
    },
    getListQuery(p) {
      let query = { ...p }
      if (query.dateRange[0]) {
        query.begin = query.dateRange[0]
      }
      if (query.dateRange[1]) {
        query.end = query.dateRange[1]
      }
      delete query.dateRange
      query.money_tags = query.money_tags.join(",")
      return query
    },
    customRow(record) {
      return {
        on: {
          click: () => {
            this.hasDetail = true
            this.detail = JSON.parse(JSON.stringify(record))
          }
        }
      }
    },
    async expand(expanded, record) {
      if (expanded && record.children.length === 0) {
        let url = window.mock ? "http://127.0.0.1:4523/m1/2234408-0-default/prj/issue/retrieve/children?apifoxApiId=59781388" : "/prj/issue/retrieve/children"
        await fetch("post", url, {
          oid: record.oid
        }).then(res => {
          record.children = res.data.map(item => {
            return {
              ...item,
              children: []
            }
          })
        })
      }
    },

    beforeGetList() {
      if (this.list[0]) {
        this.hasDetail = true
        this.detail = JSON.parse(JSON.stringify(this.list[0]))
      }
    },
    openModal(type, fill) {
      this.$refs.modal.open(type, fill)
    },
    selectActionMenu({ key }) {
      if (key === "export") {
        if (this.list.length > 0) {
          window.open(this.$toUrl(`/prj/moneylog/export?oids=${this.list.map(item => item.oid).join(',')}`, true, "api"))
        } else {
          this.$message.error('暂无财务')
        }
      }
    },
    changeSelected(list) {
      console.log(list)
      this.selectedRowKeys = list
    }
  }
}
</script>

<style lang="less" scoped>
.money_status {
  width: 23px;
  height: 23px;
  line-height: 23px;
  text-align: center;
  display: inline-block;
  color: white;
  border-radius: 50%;
  margin-right: 5px;

  &.green {
    background-color: green;
  }

  &.red {
    background-color: red;
  }
}

.app-main-content {
  /deep/ .page-card-body {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    // 表格和右侧信息的父元素 选择器
    > .body-flex {
      flex: 1;
      display: flex;
      overflow: hidden;
      background-color: #F8F8F8;
      // 左侧表格
      > .body-left-table {
        flex: 1;
        height: 100%;
        overflow-y: auto;
        overflow-x: auto;
        background: #FFF;
      }

      // 右侧信息
      > .body-right-info {
        flex-shrink: 0;
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        border-top: 1px solid #ededed;
        background-color: #FFF;

        // 可以滚动的部分
        .info-scroll-content {
          padding-right: 20px;
          overflow-y: auto;
          padding-bottom: 10px;
          padding-left: 20px;
          // 头部
          .content-head {
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            .anticon {
              margin-right: 5px;
              font-size: 16px;
            }

            .content-head-id {
              display: flex;
              align-items: center;

              .anticon {
                color: #36A3FF;
              }

              > img {
                display: inline-block;
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 8px;
              }

              .id {
                color: #4D4D4D;
                font-size: 12px;
              }
            }

            .content-head-icons {
              margin-left: auto;
            }
          }

          // 标题
          .title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 20px;
            color: #4D4D4D;
          }

          // 两列的状态
          .scroll-content-status {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 24px;
            margin-top: 13px;
            margin-bottom: 35px;

            .status-item {
              display: flex;
              align-items: center;

              .round {
                //background-color: #D4D4D4;
                width: 30px;
                height: 30px;
                border-radius: 50%;
              }

              .status-item-right {
                line-height: 1;
                margin-left: 11px;

                .status-title {
                  font-size: 16px;
                  color: #4D4D4D;
                  margin-bottom: 4px;
                  font-weight: 500;
                }

                .status-sub-text {
                  color: #A7A7A7;
                  font-size: 12px;
                }
              }
            }
          }

          // 描述输入框 后期会换成富文本编辑框
          .description {
            width: 100%;
            height: 150px;
            margin-top: 15px;
            //border: 1px solid #EDEDED;
          }

          // 文本信息展示
          .content-group-info {
            padding-top: 20px;
            // 每组信息的标题
            .group-info-title {
              margin-top: 34px;
              font-size: 18px;
              color: #4D4D4D;
              margin-bottom: 15px;
            }

            // 每组信息列表
            .group-info-list {
              .info-item {
                display: flex;
                color: #4D4D4D;
                font-size: 16px;
                align-items: center;

                &:not(:last-child) {
                  margin-bottom: 15px;
                }

                .info-key {
                  width: 150px;
                  font-weight: 500;
                }
              }

              //动态和评论
              .container-tabs {
                height: 100%;

                .ant-tabs-nav .ant-tabs-tab {
                  margin: 0;
                }

                .ant-tabs-bar {
                  margin: 0;
                }

                .ant-tabs-content {
                  height: 100%;
                }

                .tabs-content {
                  height: 100%;

                  .dynamic-items {
                    padding: 13px;
                    align-items: baseline;

                    .info {
                      padding-left: 14px;
                      font-size: 12px;
                      color: #909090;

                      .name {
                        color: #000000;
                        margin-bottom: 9px;

                        > span {
                          color: #909090;
                        }
                      }

                      .detail {
                        .icon {
                          transform: rotate(-90deg);
                          color: #909090;
                        }

                        > div {
                          margin-right: 7px;
                        }

                        > div:nth-last-child(1) {
                          margin: 0;
                        }

                        .finish {
                          color: #24B47E;
                          background-color: #E9F7F2;
                        }

                        .unfinish {
                          color: #EA0D0D;
                          background: #FDE6E6;
                        }
                      }
                    }
                  }

                  .detail-title {
                    padding-left: 16px;
                    height: 55px;
                    line-height: 55px;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CACACA;
                    font-size: 14px;
                    color: #000000;
                  }

                  .detail-items {
                    padding: 13px 41px;

                    .detail-item-1 {
                      margin-bottom: 14px;

                      .top {
                        color: rgba(0, 0, 0, 1);
                        font-size: 12px;
                        margin-bottom: 9px;

                        > div {
                          margin-right: 5px;
                        }

                        > div:nth-last-child(1) {
                          margin-right: 0;
                          color: rgba(144, 144, 144, 1);
                        }

                        .status {
                          color: #24B47E;
                          background: #E9F7F2;
                          padding: 0 5px;
                        }

                        .status.nopass {
                          color: #EA0D0D;
                          background: #FDE6E6;
                        }
                      }

                      .bottom {
                        font-size: 12px;
                        color: #909090;
                        font-weight: 500;
                        align-items: baseline;
                        height: 100%;
                        position: relative;

                        > div {
                          margin-left: 14px;
                        }

                        > div:nth-last-child(1) {
                          width: 205px;
                          color: var(--primary-color);
                          font-weight: 500;
                        }

                        > div:nth-of-type(1) {
                          margin: 0;
                        }
                      }
                    }

                    .detail-item-2 {
                      font-size: 12px;
                      color: #000000;
                      margin-bottom: 14px;

                      > button {
                        margin-right: 13px;
                      }

                      > button:nth-last-child(1) {
                        border: 1px solid var(--primary-color);
                        color: var(--primary-color);
                      }
                    }
                  }

                  .comment-items {
                    padding: 13px;
                    font-weight: 500;
                    font-size: 12px;
                    color: #000000;

                    > img {
                      width: 19px;
                      height: 19px;
                      vertical-align: middle;
                    }

                    .info {
                      margin: 0 8px;

                      > .name {
                      }

                      > .time {
                        font-weight: 400;
                        font-size: 12px;
                        color: #919191;
                      }
                    }

                    .content {
                      font-weight: 400;
                      color: #919191;
                    }
                  }
                }
              }
            }
          }
        }

        // 右侧下方固定不滚动的部分
        .right-info-footer {
          border-top: 1px solid #eee;
          flex-shrink: 0;
          height: 50px;
          // 评论部分
          .input-group {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EDEDED;

            .input {
              height: 48px;
              border: none;
              outline: none;
              flex: 1;
              padding-left: 10px;
            }

            .btn {
              cursor: pointer;
              width: 100px;
              text-align: center;

              .anticon {
                font-size: 20px;
                color: #333;
              }
            }
          }

          // 关注部分
          .follow {
            display: flex;
            height: 48px;
            align-items: center;
            justify-content: space-between;
            padding-left: 10px;

            .text {
              color: #4D4D4D;
              font-size: 16px;
              // 关注人数
              span {
                line-height: 16px;
                height: 16px;
                display: inline-block;
                background-color: #D9D9D9;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                border-radius: 4px;
                padding: 0 5px;
                margin: 0 5px;
              }
            }

            // 关注按钮
            .action {
              cursor: pointer;
              display: flex;
              align-items: center;
              padding: 0 20px;

              .anticon {
                margin-right: 5px;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
